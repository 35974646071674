<template>
  <div class="page">
    <img class="bg" :src="modelBg"/>
    <Model 
    ref="modelRef" 
    :pid="orderInfo.pid"
    :modelFile="modelFile" 
    @changeBackgound="changeBackgound" 
    @rotateControl="rotateControl" 
    @handleProgress="handleProgress" 
    @handleLoaded="handleLoaded"></Model>

    <div class="popup" v-if="showDataProcess">
        <div class="pcenter">
            <div class="text">{{ '数据加载中...'.$t }}</div>
            <van-progress class="progress" :percentage="percent" stroke-width="8px" :show-pivot="false" />
        </div>
    </div>
    <div class="popup" v-if="showProcess">
        <div class="pcenter">
            <div class="text">{{ '渲染中...'.$t }}</div>
            <van-progress class="progress" :percentage="percent" stroke-width="8px" :show-pivot="false"/>
        </div>
    </div>

    <!-- 事件弹窗 -->
    <div class="popup" v-if="modelException">
        <div class="lock-box">
            <img class="lock-logo" :src='vector'/>
              <div class="lock-text">{{ '故障模型，请联系门店处理'.$t }}</div>
              <div class="buy-btn" @click="goBack">{{ '返回'.$t }}</div>
        </div>
    </div>
    <div class="popup" v-else-if="titleCode == 2">
        <div class="lock-box">
            <img class="lock-logo" :src='vector'/>
            <div class="lock-text">{{ '3D相册体验已结束'.$t }}</div>
            <!-- <div class="buy-btn" @click="goBuy">{{ '去购买'.$t }}</div> -->
        </div>
    </div>
    <div class="popup" v-else-if="titleCode == 3">
        <div class="lock-box">
            <img class="lock-logo" :src='vector'/>
            <div class="lock-text">{{ '您购买的3D相册正在精修中，请稍候！'.$t }}</div>
        </div>
    </div>
    <div class="popup" v-else-if="digitalType == 0 && titleCode == 0">
        <div class="lock-box">
            <img class="lock-logo" :src='vector'/>
            <div class="lock-text">{{ '您未购买3D相册'.$t }}</div>
            <!-- <div class="buy-btn" @click="goBuy">{{ '去购买'.$t }}</div> -->
        </div>
    </div>

    <!-- 操作面板 -->
    <div :class="['func-box', {active: showBtn}]" >
          <div class="func-list">
              <!-- <img class="func-btn"
                  @click="funcClick(1)"
                  :src="reset"
                  />
              <img class="func-btn"
                  @click="funcClick(2)"
                  :src="save"
                  />
              <img
                  class="func-btn"
                  @click="funcClick(3)"
                  :src="isRotate"
                  /> -->
              <div class="edit-btn"
                  @click="funcClick(1)">
                  <img class="reset canv-img" :src="reset" />
                  <div class="canv-text">{{ '复位'.$t }}</div>
              </div>
              <div class="edit-btn"
                  @click="funcClick(2)">
                  <img class="save canv-img" :src="save" />
                  <div class="canv-text">{{ '保存'.$t }}</div>
              </div>
              <div class="edit-btn"
                  @click="funcClick(3)">
                  <img :class="['canv-img',rotateText]" :src="isRotate" />
                  <div class="canv-text">{{ '旋转'.$t }}</div>
              </div>
              <div class="edit-btn"
                  @click="showPopup">
                  <img class="changeBg canv-img" :src="changeBg" />
                  <div class="canv-text">{{ '换背景'.$t }}</div>
              </div>
              <div class="edit-btn"
                  @click="goAr">
                  <img class="canv-img ar" :src="ar" />
                  <div class="canv-text">{{ '体验AR'.$t }}</div>
              </div>
              <!-- <div :class="['edit-btn', {hui: iShareFlag}]"
                  @click="screenshot">
                  <img class="canv-img" src="{{iShareFlag?'/static/images/photo3D/icon_3d_share_gray.png':'/static/images/photo3D/icon_3d_share.png'}}" />
                  <div class="canv-text">{{ '去分享'.$t }}</div>
              </div> -->
              
          </div>
          <div class="func-change" @click="showBtnClick">
              <img class="triangle" :src="arrow"/>
              <div >{{showBtn ? '展开'.$t : '收起'.$t}}</div>
          </div>
    </div>

    <!-- 提示面板 -->
    <div class="countDown" v-if="experienceLeft">
      <div class="text">
          {{'体验还剩'.$t}}{{expireTime}}{{'天'.$t}}
      </div>
      <div class="feedback" @click="feedbackClick">
          <img class="edit" :src="edit"/>
          {{'模型问题反馈'.$t}}
      </div>
      <div class="receive" v-if="iShareFlag" @click="receiveJump">
          {{'我要认领'.$t}}
      </div>
    </div>

     <!-- <div class="cover-canvas">
      <div class="cover-canvas_con experience" >
          <div :class="['buy-btn', {disabled: iShareFlag}]" @click="goBuy">
              <img class="canv-img" src="/static/images/photo3D/buy.png" />
              <div class="buy-text" v-if="digitalType == 0 || digitalType == 4">修模模型瑕疵（美化）+3D打印手办</div>
              <div class="buy-text" v-else>{{ '购买3D真人手办'.$t }}</div>
          </div>
          <div class="hint-btn" @click="hintClick">
              <img class="hint-icon" :src="hint" />
              <div>{{ '关于模型瑕疵'.$t }}</div>
          </div>
      </div>
    </div> -->
  <!-- 相册背景 -->
  <van-popup v-model:show="showPop" round position="bottom" @close="onClose">
      <van-tabs v-if="showPop" :active="active" offset-top="30px" sticky @change="onChange" title-active-color="#333" title-inactive-color="#999" color="#52B139">
          <van-tab :title="'纯色背景'.$t">
              <div class="tabs-con">
                  <div :class="['tabs-con_li', {cur: item.checked}]" @click="changeColor(index)"  v-for="item, index in itemList[active]" :key="item.id">
                      <div class="img-column" :style="{backgroundColor: item.color}"></div>
                      <div class="btn-check" v-if="item.checked">{{ '已选择'.$t }}</div>
                  </div>
              </div>
          </van-tab>
          <van-tab :title="'主题背景'.$t">
              <div class="tabs-box_item">
                  <div class="tabs-con">
                      <div :class="['tabs-con_li', {cur: item.checked}]" @click="changeColor(index)" v-for="item, index in itemList[active]" :key="item.id">
                          <img class="img-column" :src="item.cover_path" />
                          <div class="btn-check" v-if="item.checked">{{ '已选择'.$t }}</div>
                      </div>
                  </div>
              </div>
          </van-tab>
      </van-tabs>
  </van-popup>


  
  <!-- 微信分享 -->
  <!-- <van-popup show="{{ sharePopSign }}" position="bottom" @close="hideSharePop" custom-class="share">
      <div class="share-bar">
          <text>{{ '保存到相册'.$t }}</text>
          <van-icon name="cross" size="20px" @click="hideSharePop" />
      </div>
      <div class="share-con">
          <div class="share-con_canva">
              <image src="{{iphonebg}}"  style="width: 342px;" />
              <div class="picture">
                  <image src="{{cardbg}}"  />
              </div>
              <image class="code" :src="codeImg" />
          </div>
      </div>
      <button @click="saveShareImg">{{ '保存图片'.$t }}</button>
      <div class="btip">{{ '保存图片到手机相册后，将图片分享到您的圈子'.$t }}</div>
  </van-popup> -->
  </div>
</template>

<script lang="tsx" setup>
import { useStore } from '@/stores'
import * as api from '@/api/photo_3d'
import UAParser from 'ua-parser-js';
const store = useStore();
const orderInfo = ref({})
const showBtn = ref(false)
const digitalType = ref(null)
const titleCode = ref(null)
const expireTime = ref(null)
const showDataProcess = ref(true)
const showProcess = ref(false)
const showPop = ref(false)
const modelException = ref(false)
const remainingExperienceStatus = ref(null)
const percent = ref(0)
import { useTokenStore } from '@/stores/token'
const tokenStore = useTokenStore();
const { isWechat } = tokenStore;
const bgMap = ref()
const itemList = ref()
const active = ref(0)
const modelRef = ref(null)
const modelBg = ref("https://wechat.suwa3d.com/data/mini/main_bg.png")
const modelFile = ref()
const iShareFlag = ref(false)
const router = useRouter();
import reset from "@/assets/photo3D/reset.png"
import save from "@/assets/photo3D/save.png"
import rotate from "@/assets/photo3D/rotate.png"
import stop from "@/assets/photo3D/stop.png"
import changeBg from "@/assets/photo3D/icon_3d_bg.png"
import ar from "@/assets/photo3D/icon_3d_ar.png"
import share from "@/assets/photo3D/icon_3d_share_gray.png"
import arrow from "@/assets/common/arrow.png"
import vector from "@/assets/photo3D/vector.png"
import edit from "@/assets/photo3D/edit.png"
import hint from "@/assets/photo3D/hint.png"
import { closeToast, showFailToast, showLoadingToast } from 'vant';
const autoRotate = ref(false)
const userInfo = ref({})

onMounted(()=> {
    userInfo.value = tokenStore.userInfo;
    orderInfo.value = store.orderInfo;
    digitalType.value = store.orderInfo.real_digital_type;
    titleCode.value = store.orderInfo.remaining_experience.title_code;
    expireTime.value = store.orderInfo.remaining_experience.day;
    remainingExperienceStatus.value = store.orderInfo.remaining_experience.status;
    getBg(store.orderInfo.pid)
    getModel(store.orderInfo.pid)
})

// 自动旋转控制
const isRotate = computed(()=> {
    return autoRotate.value ? stop : rotate
})

// 自动旋转控制
const rotateText = computed(()=> {
    return autoRotate.value ? "stop" : "rotate"
})

// 体验天数
const experienceLeft = computed(()=> {
  let val = orderInfo.value;
  return (val.status < 7000 || remainingExperienceStatus != 0 ) && expireTime > 0
})


// 模型加载进度
const handleProgress = (detail) => {
    if (detail < percent.value) return;
    // 兼容其他机型渲染慢
    if (detail == 1) {
      setTimeout(() => {
        if (showProcess.value) {
          // 提示模型有问题
          showProcess.value = false
          modelException.value = true
        }
      }, 10000);
    }
    percent.value = detail
}

// 模型加载完成
const handleLoaded = (detail) => {
    setTimeout(() => {
        showProcess.value = false;
    }, 1000)
}

// 获取模型背景
const getBg = (pid: number) => {
  api.get3dBgList(pid).then((res: any) => {
    itemList.value = [
        res.pure_color,
        res.topic,
    ]
    bgMap.value = res;
  }).catch(err=> {
    console.log(err)
  })
}

// 获取模型路径
const getModel = (pid: any) => {
  api.glb(pid).then((data: any) => {
    if (data && data.s) {
        let _data = {
            iShare: data.s,
            id: data.id,
        }
        // 只有分享的才需要这么处理
        if (data.isShare) {
            _data.expireTime = data.expireTime || '';
            _data.remainingExperienceStatus = data.remainingExperienceStatus || '';
            _data.digitalType = data.digitalType || '';
            _data.titleCode = data.titleCode || '';
            _data.oid = data.oid || '';
        }
        // that.setData(_data)
        }
        modelFile.value = data.url;
        showProcess.value =  true
        showDataProcess.value =  false

  }).catch(err=> {
    console.log("loaded failed", err);

    closeToast();
    showFailToast((err && err.message));
        
  })
}


// 修改背景颜色
const changeColor = (index) => {
    showLoadingToast({message: "加载中...".$t,forbidClick: true,})
    let param;
    // if(audio && !audioSwitch) {
    //   this.setData({
    //     audioShow: true,
    //     audioPath: audio,
    //     audioSwitch: true
    //   })
    // }else { 
    //   this.audioCtx.stop() // 停止

    //   this.setData({
    //     audioSwitch: false
    //   })
    // }
    switch (active.value) {
      case 0: 
        //颜色背景
        param = itemList.value[active.value][index].color;
        modelRef.value.changeColorChild(active, index, param);
        break;
      case 1:
        // 自定义图片背景
        modelBg.value = itemList.value[active.value][index].bg_path;
        modelRef.value.changeTransparent(active, index, param);
        console.log("modelBg.value",modelBg.value)
        break;
    }
  }

const changeBackgound = (e) => {
    let list = itemList.value;
    list.map((item, index) => {
        if (index === active.value) {
        item.map((subit, subin) => {
            if (subin != e) {
            subit.checked = false;
            return
            }
            subit.checked = true;
        })
        } else {
        item.map(subit => {
            subit.checked = false;
        })
        }
    })
    itemList.value = list;

    setTimeout(() => { closeToast() }, 500)
}

// 关闭背景选择弹窗
const onClose = () => showPop.value = false;

// 选择背景
const onChange = (e) => active.value = e;

// 返回
const goBack = () => history.back();

// 按钮功能点击
const funcClick = (id) => {
    modelRef.value.controlEvent(id)
    // showBtn.value = false;
}

// 背景面板打开
const showPopup = () => {
    showPop.value = true
}

// 
const rotateControl = (val: boolean) => {
    console.log("val",val)
    autoRotate.value = val;
}

 // 问题反馈跳转
const feedbackClick = () => {
    // let {id, openid, unionid} = this.data
    // router.push({
    //   path: "/pages/feedback/feedback?pid=" + id + "&openid=" + openid + "&unionid=" + unionid
    // })
}

// 我要认领跳转
const receiveJump = () => {
    // let {id, iShareFlag} = this.data
    // router.push({
    //   path: "/pages/receive/receive?pid=" + id + "&share=" + iShareFlag
    // })
}

// 模型瑕疵了解
const hintClick = () => {
  let url = "https://mp.weixin.qq.com/s?__biz=MzkwMDYzMTY3NA==&mid=2247483820&idx=1&sn=ac0d9e46ec012bc79531bad1e4e4e8db&chksm=c0405cb9f737d5af0486ae4de24192994cfc625d3c6750d0a8c33ff53909046a4c5141df05af#rd"
//   let param = encodeURIComponent(url);
  window.open(url)
}

// 按钮面板开关
const showBtnClick = () => {
  showBtn.value = !showBtn.value
}

const goAr = () => {
    // 打开摄像头
    const uaParser = new UAParser();
    const userAgent = uaParser.getResult();
    let str;
    if(userAgent.browser.name == "Mobile Safari") {
        str = "ios"
    }else {
        str = "android"
    }
    router.push({
        path: "/ar",
        query: {str: str},
    })
}
// const screenshot = (num: number) => {

// }

</script>


<style lang="less" scoped>
.bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
//   background-image: url('https://wechat.suwa3d.com/data/mini/main_bg.png');
//   background-size: cover;
  z-index: -1;
}

.page {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.webgl {
    display: block;
    width: 100vw;
    height: 100vh;
}

.hint-pop {
    position: absolute;
    top: 12%;
    left: 0;
    width: 100%;
    height: 60px;
    background: #E9F3EE;
    .hint-wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        width: 343px;
        height: 100%;
        margin: 0 auto;
        .hint-text {
            position: relative;
            width: 316px;
            font-size: 12px;
            color: #FF8F1F;
            line-height: 18px;
        }
    }
}


.cover-canvas {
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 75px;
    background: #FFFFFF;
    .cover-canvas_con {
        display: flex;
        position: relative;
        justify-content: space-between;
        padding-bottom: calc(constant(safe-area-inset-bottom));
        padding-bottom: calc(env(safe-area-inset-bottom));
        width: 295px;
        height: 75px;
        margin: 0 auto;
        padding-top: 11px;
        .buy-btn {
            display: flex;
            align-items: center;
            justify-content: space-around;
            position: relative;
            width: 268px;
            height: 34px;
            background: #DC5F39;
            color: #fff;
            border-radius: 4px;
            font-size: 14px;
            padding-left: 7.5px;
            box-sizing: border-box;
            .canv-img {
                position: relative;
                width: 8.5px;
                height: 8px;
            }
            .buy-text {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
            }
        }
        .disabled {
            opacity: .2;
        }
        .hint-btn {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: relative;
            height: 34px;
            color: #5E636E;
            font-size: 10px;
            .hint-icon {
                width: 20px;
                height: 20px;
            }
        }
    }
    .experience {
        width: 339px;
    }
}

.btn-box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    left: 5%;
    bottom: 12%;
    width: 95px;
    height: 143px;
    .layer {
        display: flex;
        align-items: center;
        position: relative;
        width: 100%;
        height: calc(143/3)px;
        .click-btn {
            position: relative;
            width: 48px;
            height: 48px;
            margin-right: 11px;
        }
        .func-btn {
            display: none;
            position: relative;
            width: 36px;
            height: 36px;
            opacity: 0;
            transition: .25s all;
        }
        .reset, .rotate {
            margin-left: 17.5px;
        }
        
    }
    
}
.active .layer .func-btn{
    display: block;
    opacity: 1;
}

.func-box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    right: 5%;
    bottom: 21%;
    width: 36px;
    // height: 285px;
    height: 240px;
    overflow: hidden;
    .func-list {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        position: relative;
        // height: 255px;
        height: 215px;
        transition: all .25s linear;
        z-index: 1;
        .edit-btn {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: relative;
            width: 36px;
            height: 36px;
            background: rgba(255, 255, 255, 0.4);
            border-radius: 10px;
            color: #5E636E;
            transition: .25s all;
            font-size: 8px;
            .canv-img {
                position: relative;
                margin-bottom: 5px;
            }
            .reset {
                width: 14px;
                height: 15px;
            }
            .save {
                width: 14px;
                height: 16px;
            }
            .rotate {
                width: 14px;
                height: 14px;
            }
            .stop {
                width: 14px;
                height: 14px;
            }
            .changeBg {
                width: 14px;
                height: 14px;
            }
            .ar {
                width: 14px;
                height: 14px;
            }
            .canv-text {
                word-break: break-all;
            }
        }
    }
    .func-change {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 36px;
        height: 20px;
        background: #fff;
        border-radius: 10px;
        color: #5E636E;
        font-size: 8px;
        z-index: 2;
        word-break: break-all;
        .triangle {
            width: 13px;
            height: 13px;
            transition: all .25s linear;
        }
    }
    
}

.active {
    .func-list{
        top: 285px;
    }
    .func-change .triangle{
        transform: rotate(180deg);
    }
}

.countDown {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 5%;
    bottom: 11%;
    width: 85px;
    height: 45px;
    background: #ffffff;
    border-radius: 10px;
    box-sizing: border-box;
    > div:last-child {
        border-bottom: none;
    }
    .text {
        display: flex;
        justify-content: center;
        align-items: center;
        width: auto;
        height: 50%;
        color: #E6A23C;
        font-size: 12px;
        font-weight: 500;
        font-family: Source Han Sans CN-Medium;
        border-bottom: 1px solid #D8D8D8;
    }
    .feedback, .receive{
        display: flex;
        justify-content: center;
        align-items: center;
        width: auto;
        height: 50%;
        color: #5E636E;
        font-size: 8px;
        font-weight: 500;
        font-family: Source Han Sans CN-Medium;
        .edit {
            position: relative;
            width: 12px;
            height: 12px;
            margin-right: 5px;
        }
    }
    .feedback {
        border-bottom: 1px solid #D8D8D8;
    }
}

.canv-li {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: relative;
    position: 33px;
    height: 40px;
    image {
        width: 24px;
        height: 24px;
        display: block;
    }
    .canv-text {
        font-size: 10px;
        color: #5E636E;
    }
}


.canv-li.hui image {
    opacity: 0.2;
}

.modals {
    position: fixed;
    z-index: 9;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    transition-duration: .3s;
}

.tabs {
    height: 44px;
    border-radius: 12px 12px 0px 0px;
    border: 2px solid #FFFFFF;
    display: flex;
    align-items: center;
}

.tabs>div {
    width: 50%;
    text-align: center;
    line-height: 44px;
    position: relative;
}

.tabs>div::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    width: 56px;
    height: 3px;
    border-radius: 2px;
    margin: 0 auto;
}

.tabs>div.cur::after {
    background: #52B139;
}

.tabs-box {
    height: 254px;
}

.tabs-con {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 20px 15px 20px;
    box-sizing: border-box;
    height: 100%;
    height: 250px;
    overflow-y: auto;
    overflow-x: hidden;
}

.tabs-con_li {
    height: 86px;
    margin-bottom: 10px;
    border: 2px solid transparent;
    border-radius: 12px;
    position: relative;
}

.tabs-con_li.cur {
    border-color: rgba(82, 177, 57, 0.5);
}

.tabs-con_li .btn-check {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 56px;
    height: 24px;
    background: #55B038;
    border-radius: 8px;
    font-size: 12px;
    text-align: center;
    line-height: 24px;
    color: #fff;
}

.tabs-con_li .img-column {
    width: 157px;
    height: 82px;
    background: #C9CFD8;
    border: 4px solid #fff;
    border-radius: 12px;
}

.tabs-box_item {
    position: relative;
    padding-bottom: 25px;
}

.van-tabs__nav {
    border-bottom: 2px solid #F7F7F7;
}

/* 微信分享: */
.share {
    background: #fff;
    color: #3D3D3D;
    font-size: 12px;
}

.share-bar {
    display: flex;
    justify-content: space-between;
    height: 48px;
    line-height: 48px;
    padding: 0 20px;
    font-size: 18px;
    background: #F2F2F2;
}

.share-con {
    box-sizing: border-box;
    padding: 10px 102px;
}

.share-con_canva {
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    text-align: center;
}

.share-con_canva .picture {
    position: absolute;
    overflow: hidden;
    width: 147.5px;
    height: 160px;
    width: 149px;
    height: 165px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 16px;
    border: 2px solid #2C6818;
}

.share-con_canva .picture image {
    display: block;
    border-radius: 20px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
}

.share-con_canva .code {
    width: 46px;
    height: 46px;
    position: absolute;
    left: 20px;
    bottom: 23.5px;
}

.share button {
    font-size: 16px;
    height: 50px;
    line-height: 50px;
    margin: 0 15px;
    border-radius: 8px;
    background: #079A41;
    color: #fff;
    border: none;
    outline: none;
}

.share .btip {
    padding: 10px 0;
    text-align: center;
}

.nav-wrap {
    position: fixed;
    width: 100%;
    top: 0;
    color: #000;
    z-index: 9999999;
}

.nav-wrap .nav-capsule {
    display: flex;
    align-items: center;
    margin-left: 15px;
    width: 70px;
    justify-content: space-between;
    height: 100%;
}

.nav-wrap navigator {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
}

.nav-wrap image {
    width: 24px;
    height: 24px;
    margin-top: 2px;
}

.popup {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
    text-align: center;
    .lock-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        height: 192px;
        margin-top: -96px;
        .lock-logo {
            position: relative;
            width: 80px;
            height: 80px;
            margin-bottom: 24px;
        }
        .lock-text {
            position: relative;
            height: 36px;
            font-size: 18px;
            color: #FFFFFF;
            font-weight: 400;
            font-family: Source Han Sans CN-Medium;
            margin-bottom: 24px;
        }
        .buy-btn {
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            width: 160px;
            height: 44px;
            background: #009944;
            color: #ffffff;
            font-size: 16px;
            font-weight: 500;
            font-family: Source Han Sans CN-Medium;
            border-radius: 10px;
        }
    }
}


.text {
    font-size: 15px;
    color: #fff;
}

.progress {
    width: 80%;
    margin: 0 auto;
    margin-top: 5px;
}

.pcenter {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
}
</style>
