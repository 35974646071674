<template>
  <!-- <template v-if="route.query?.str == 'ios'">
    <arIos :pid="pid" :modelFile="modelFile" @backTo="backTo"  @handleProgress="handleProgress" 
    @handleLoaded="handleLoaded"></arIos>
  </template > -->
  <!-- <template v-else> -->
    <arFrame :pid="pid" :modelFile="modelFile" @backTo="backTo"  @handleProgress="handleProgress" 
    @handleLoaded="handleLoaded"></arFrame>
  <!-- </template> -->
    <div class="popup" v-if="showProcess">
        <div class="pcenter">
            <div class="text">{{ '渲染中...'.$t }}</div>
            <van-progress class="progress" :percentage="percent" stroke-width="8px" :show-pivot="false"/>
        </div>
    </div>
  <div class="exit" @click="backTo">
    <img class="exit_img" :src="exit" />
    <div>退出AR</div>
  </div>
</template>

<script setup lang="ts">
import exit from "@/assets/photo3D/icon_exit.png";
import * as api from '@/api/photo_3d'
const route = useRoute()
import { showFailToast } from "vant";
import { useStore } from '@/stores'
const store = useStore();
const backTo = () => history.back()
const modelFile = ref()
const percent = ref(0)
const orderInfo = ref()
const pid = ref();
const showProcess = ref(false)
onMounted(()=> {
    orderInfo.value = store.orderInfo;
    pid.value = store.orderInfo.pid
    getModel(pid.value)
})


// 获取模型路径
const getModel = (pid: any) => {
  api.glb(pid).then((data: any) => {
    if (data && data.s) {
        // let _data = {
        //     iShare: data.s,
        //     id: data.id,
        // }
        // 只有分享的才需要这么处理
        // if (data.isShare) {
        //     _data.expireTime = data.expireTime || '';
        //     _data.remainingExperienceStatus = data.remainingExperienceStatus || '';
        //     _data.digitalType = data.digitalType || '';
        //     _data.titleCode = data.titleCode || '';
        //     _data.oid = data.oid || '';
        // }
        // that.setData(_data)
        }
        modelFile.value = data.url;
    

  }).catch(err=> {
    console.log("loaded failed", err);
    showFailToast(err && err.message);
        
  })
}
// 模型加载进度
const handleProgress = (detail) => {
    showProcess.value =  true
    if (detail < percent.value) return;
    // 兼容其他机型渲染慢
    if (detail == 1) {
      setTimeout(() => {
        if (showProcess.value) {
          // 提示模型有问题
          showProcess.value = false
        }
      }, 10000);
    }
    percent.value = detail
}

// 模型加载完成
const handleLoaded = (detail) => {
    setTimeout(() => {
        showProcess.value = false;
    }, 1000)
}
</script>

<style lang="less" scoped>
.exit {
  position: absolute;
  left: 30px;
  bottom: 30px;
  font-size: 14px;
  color: #fff;
  text-align: center;
  .exit_img {
    width: 38px;
    height: 39px;
    display: block;
    margin: 0 auto 6px;
  }
}
.popup {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
    text-align: center;
}
.text {
    font-size: 15px;
    color: #fff;
}

.progress {
    width: 80%;
    margin: 0 auto;
    margin-top: 5px;
}

.pcenter {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
}
</style>